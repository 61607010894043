<template>
    <div class="portal-login" :style="{ backgroundImage: `url(${bgImg})` }">
        <div class="portal-login-container">
            <div class="portal-login-container-logo">
                <img :src="logo" />
            </div>
            <div class="portal-login-container-title">
                <span class="portal-login-container-info1">{{ title }}</span>
            </div>
            <div class="portal-login-container-form">
                <cgs-form ref="sceneForm" :model="formState" :hideRequiredMark="true" layout="vertical"
                    class="portal-login-container-form-main">
                    <cgs-form-item name="userName" :rules="[{ required: true, message: $t('accountNullError') }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-gerenzhanghu portal-login-container-form-icon"></span>
                            <span>{{ $t('account') }}</span>
                        </template>
                        <cgs-input class="portal-login-container-form-input" v-model:value="formState.userName"
                            @press-enter="handleLogin" :placeholder="$t('accountNullError')" />
                    </cgs-form-item>

                    <cgs-form-item name="password" :rules="[{ required: true, message: $t('passwordNullError') }]">
                        <template #label>
                            <span
                                class="cgs-portal-iconfont cgs-portal-icon-mima portal-login-container-form-icon"></span>
                            <span>{{ $t('password') }}</span>
                        </template>
                        <cgs-input class="portal-login-container-form-input" type="password"
                            v-model:value="formState.password" @press-enter="handleLogin"
                            :placeholder="$t('passwordNullError')" />
                    </cgs-form-item>
                </cgs-form>
            </div>
            <div class="portal-login-container-button">
                <cgs-button type="primary" :loading="buttonLoading" @click="handleLogin">{{ buttonLoadText
                    }}</cgs-button>
            </div>
            <div class="portal-login-container-bottom">
                <span v-if="releaseType.toLowerCase() === 'public'" @click="toRegister">{{ $t('register') }}</span>
                <span @click="toFindPassword">{{ $t('findPassword') + $t('symbol.?') }}</span>
            </div>
        </div>
        <a class="portal-login-registration-info" href="http://beian.miit.gov.cn" target="_blank">{{ registration }}</a>
        <select-main-page :visible="mainPage.visible" :options="mainPage.options" @close="closeSelectMainPageModal"
            @selected="selectedMainPage">
        </select-main-page>
    </div>
</template>
<script>
import {
    defineComponent,
    ref,
    reactive,
    computed,
    onMounted,
    getCurrentInstance
} from 'vue';
import {
    useRouter
} from 'vue-router'
import {
    useI18n
} from 'vue-i18n'
import {
    watchSystemInfo,
    watchSystemVersion
} from '@/utils/author.js'
import {
    getTenantInfo
} from '@/api/tenant'
import {
    loginByUsername,
    getUserInfo,
} from "@/api/user";
import {
    getSysLib
} from "@/api/system";
import {
    setToken,
    // setHeadPic,
    setUserInfo,
    setUserAuthority,
    setTenantInfo,
    setEntry,
    setFastLogin,
    setSysLib,
    deleteFastLogin
} from "@/utils/author.js";
import selectMainPage from './selectMainPage.vue';

export default defineComponent({
    name: "login",
    components: {
        selectMainPage
    },
    setup () {
        let {
            proxy
        } = getCurrentInstance();
        let router = useRouter()
        const {
            t
        } = useI18n()
        const formState = reactive({
            userName: '',
            password: '',
        })
        const sceneForm = ref(null)
        const buttonLoading = ref(false)
        const buttonLoadText = ref(t('signIn'))
        const mainPage = reactive({
            visible: false,
            options: []
        })
        const state = reactive({
            sysInfo: {},
            sysVersion: {}
        })
        let userInfo = {}
        let userAuthority = {}
        const toRegister = () => {
            router.push({
                path: '/register'
            })
        }
        const toFindPassword = () => {
            router.push({
                path: '/findPassword'
            })
        }
        const closeSelectMainPageModal = () => {
            // this.$ws.reconnect()
            buttonLoading.value = false;
            buttonLoadText.value = t('signIn');
            mainPage.visible = false
            mainPage.options = []
        }
        const selectedMainPage = (_mainPage) => {
            setFastLogin(_mainPage);
            setUserInfo(userInfo);
            setUserAuthority(userAuthority);
            getSysLib().then(sysLibResult => {
                if (sysLibResult.code === 200) {
                    setSysLib(sysLibResult.data)
                }
            })

            getTenantInfo({
                tenantId: userAuthority.tenantId
            }).then((tenantInfo) => {
                if (tenantInfo && tenantInfo.success) {
                    setTenantInfo(tenantInfo.data)
                    setEntry({
                        type: 'self'
                    })
                    window.history.replaceState(null, "", _mainPage.pageUrl);
                    window.history.go(0);

                }
            })
            // this.$ws.reconnect()
            buttonLoading.value = false;
            buttonLoadText.value = "登录";
        }
        const handleLogin = async () => {
            try {
                await sceneForm.value.validateFields()
                buttonLoading.value = true;
                buttonLoadText.value = `${t('loggingIn')}...`;
                let _loginResult = await loginByUsername({
                    Username: formState.userName,
                    Password: formState.password
                })
                if (_loginResult.Code !== 200) {
                    proxy.$message.error(t('logginErr'))
                    buttonLoading.value = false;
                    buttonLoadText.value = t('signIn');
                } else {
                    await setToken(_loginResult.Data["Token"]);
                    // setHeadPic(data.Data['avatar']);
                    let _userInfoResult = await getUserInfo()
                    if (_userInfoResult && _userInfoResult.success) {
                        userInfo = _loginResult.Data
                        userAuthority = _userInfoResult.data
                        let _roleName = userAuthority.roleName
                        let _pageAuths = userAuthority.pageAuths
                        if (['Administrator', 'AppAdministrator'].includes(
                            _roleName) || _pageAuths.length === 0) {
                            await setUserInfo(userInfo);
                            await setUserAuthority(userAuthority);
                            try {
                                let sysLibResult = await getSysLib()
                                if (sysLibResult.code === 200) {
                                    setSysLib(sysLibResult.data)
                                }
                            } catch (err) {
                                console.error(err)
                            }
                            let tenantInfo = await getTenantInfo({
                                tenantId: userAuthority.tenantId
                            })
                            if (tenantInfo && tenantInfo.success) {
                                await setTenantInfo(tenantInfo.data)
                                await setEntry({
                                    type: 'self'
                                })
                                router.push({
                                    path: "/home"
                                });
                                window.themeManager.replaceTheme()
                                window.authManager.init()

                            }
                            // this.$ws.reconnect()
                            buttonLoading.value = false;
                            buttonLoadText.value = t('signIn');
                        } else if (!['Administrator', 'AppAdministrator']
                            .includes(
                                _roleName) && _pageAuths.length ===
                            1) {
                            selectedMainPage(_pageAuths[0])
                        } else {
                            mainPage.options = _pageAuths
                            mainPage.visible = true
                        }
                    }
                }
            } catch (err) {
                buttonLoading.value = false;
                buttonLoadText.value = t('signIn');
                console.error(err)
            }
        }
        onMounted(() => {
            deleteFastLogin()
            watchSystemInfo((newVal) => {
                state.sysInfo = newVal
            })
            watchSystemVersion((newVal) => {
                state.sysVersion = newVal
            })
        })
        const releaseType = computed(() => {
            let _type = ''
            if (state.sysVersion && state.sysVersion.releaseType) {
                _type = state.sysVersion.releaseType
            }
            return _type
        })
        const title = computed(() => {
            let _title = ''
            if (state.sysInfo && state.sysInfo.name) {
                _title = state.sysInfo.name.toUpperCase()
            }
            return _title
        })
        const registration = computed(() => {
            let _registration = ''
            if (state.sysInfo && state.sysInfo.domainRegistrationInfo) {
                _registration = state.sysInfo.domainRegistrationInfo
            }
            return _registration
        })
        const bgImg = computed(() => {
            let _img = ''
            if (state.sysInfo && state.sysInfo.bgImg) {
                _img = state.sysInfo.bgImg
            }
            return _img
        })
        const logo = computed(() => {
            let _logo = ''
            if (state.sysInfo && state.sysInfo.logo) {
                _logo = state.sysInfo.logo
            }
            return _logo
        })
        return {
            formState,
            buttonLoading,
            buttonLoadText,
            mainPage,
            state,
            releaseType,
            title,
            registration,
            bgImg,
            logo,
            sceneForm,


            closeSelectMainPageModal,
            selectedMainPage,
            handleLogin,
            toRegister,
            toFindPassword
        }
    }
});
</script>

<style lang="less" scoped>
@import "~@style/less/theme.less";

/* .vue-auth-box_{
    top: 50%;
  } */
.portal-login {
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    background-size: 100% 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.portal-login-container {
    z-index: 100;
    /* align-items: center; */
    padding: 40px 30px;
    border-radius: 5px;
    background-color: white;
    text-align: center;
    width: 445px;
    // height: 410px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}

.portal-login-container-logo {
    width: 100%;
    height: 40px;
}

.portal-login-container-logo img {
    width: 40px;
    height: 40px;
}

.portal-login-container-title {
    align-items: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.portal-login-container-info1 {
    .big-title
}

.portal-login-container-form {
    text-align: left;
}

.portal-login-container-button {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 42px;
}

.portal-login-container-button button {
    width: 100%;
    height: 42px;
}

.portal-login-container-bottom {
    height: 20px;
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    // color: @font-color-3;
    z-index: 1;
}

.portal-login-container-bottom span {
    color: #999999;
    cursor: pointer;
}

.portal-login-container-bottom span:hover {
    color: @primary-color;
}


.portal-login-registration-info {
    color: #333;
    text-align: center;
    position: absolute;
    width: 100%;
    background-color: #FFF;
    bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
}

.portal-login-registration-info:hover {
    color: #222;
    text-decoration: underline
}

.portal-login-container-form-icon {
    margin-right: 5px;
}
</style>

<style scoped>
.portal-login-container-form-input.cgs-design-input.ant-input {
    line-height: 32px;
    border-radius: 5px;
}
</style>

<style>
.portal-login-container-form-main.cgs-design-form .ant-form-item-label>label {
    line-height: 32px;
    height: 41px;
    color: #333333;
    width: 78px;
}

.portal-login-container-form-main .ant-form-item label:after {
    content: none;
}
</style>
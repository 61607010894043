<!--
*@description 
*@author 杜超峰
*@date 2023/04/27 23:03:41
!-->

<template>
    <div class="portal-menu-authority">

        <div class="portal-menu-authority-wrapper">
            <div class="portal-menu-authority-header">
                <div class="portal-menu-authority-header-left">
                    <span class="portal-menu-authority-return-router cgs-portal-iconfont cgs-portal-icon-fanhui"
                        @click="goBack"></span>
                    <span class="portal-menu-authority-header-content">{{ $t('auth.Description') }}</span>
                    <span class="portal-menu-authority-appid" @click="state.copyModalConfig.visible = true">{{ $t(
                            'auth.getAppID') }}</span>
                </div>
                <div class="portal-menu-authority-header-right">
                    <cgs-tabs v-model:activeKey="state.authTypeActiveKey" mode="button" @change="onChangeAuthType">
                        <cgs-tab-pane v-for="item in state.authTypeList" :key="item.key">
                            <template #tab>
                                <span>
                                    <span class="cgs-portal-iconfont" :class="item.icon"></span>
                                    {{ item.label }}</span>
                            </template>
                        </cgs-tab-pane>
                    </cgs-tabs>
                </div>
            </div>
            <div class="portal-menu-authority-content">
                <admin-role v-if="state.authTypeActiveKey === 'AppAdministrator'" v-model:role-list="adminRoleList">
                </admin-role>
                <role-role v-if="state.authTypeActiveKey === 'OtherRole'" v-model:role-list="roleRoleList"></role-role>
                <page-role v-if="state.authTypeActiveKey === 'PageRole'" v-model:role-list="pageRoleList"></page-role>
            </div>
        </div>
        <copy-app-id :visible="state.copyModalConfig.visible" :appId="sceneId" @close="closeCopyModal"></copy-app-id>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    onMounted,
    reactive
} from 'vue'
import {
    useRouter
} from 'vue-router'
import {
    useI18n
} from 'vue-i18n'
import {
    getRoleList
} from '@api/scene'
import adminRole from './admin-role.vue'
import roleRole from './role-role.vue'
import pageRole from './page-role.vue'
import copyAppId from './copyAppId.vue'
const auth_Type_List = [{
    key: 'AppAdministrator',
    label: '',
    icon: 'cgs-portal-iconfont cgs-portal-icon-guanliyuan'
},
{
    key: 'OtherRole',
    label: '',
    icon: 'cgs-portal-iconfont cgs-portal-icon-jiaoseguanli'
},
{
    key: 'PageRole',
    label: '',
    icon: 'cgs-portal-iconfont cgs-portal-icon-jiaose'
}
]
export default defineComponent({
    components: {
        adminRole,
        roleRole,
        pageRole,
        copyAppId
    },
    props: {},

    setup () {
        const { t } = useI18n()
        const router = useRouter();
        const state = reactive({
            authTypeActiveKey: 'AppAdministrator',
            authTypeList: [],
            authList: [],
            copyModalConfig: {
                visible: false
            }
        })
        state.authTypeList = computed(() => {
            return auth_Type_List.map(_item => {
                _item.label = t('auth.' + _item.key)
                return _item
            })
        })
        onMounted(() => {
            _init()
        })
        let sceneType = computed(() => {
            return router.currentRoute.value.params.type
        })
        let sceneId = computed(() => {
            return router.currentRoute.value.params.id
        })
        const adminRoleList = computed({
            get: () => {
                let _list = []
                _list = state.authList.filter(_item => _item.roleAlias === 'AppAdministrator')
                return _list
            },
            set: (val) => {
                let _authList = state.authList.filter(_item => _item.roleAlias !==
                    'AppAdministrator')
                _authList = _authList.concat(val)
                state.authList = _authList
            }
        })
        const roleRoleList = computed({
            get: () => {
                let _list = []
                _list = state.authList.filter(_item => _item.roleAlias === 'OtherRole')
                return _list
            },
            set: (val) => {
                let _authList = state.authList.filter(_item => _item.roleAlias !== 'OtherRole')
                _authList = _authList.concat(val)
                state.authList = _authList
            }
        })
        const pageRoleList = computed({
            get: () => {
                let _list = []
                _list = state.authList.filter(_item => _item.roleAlias === 'PageRole')
                return _list
            },
            set: (val) => {
                let _authList = state.authList.filter(_item => _item.roleAlias !== 'PageRole')
                _authList = _authList.concat(val)
                state.authList = _authList
            }
        })
        const _init = async () => {
            try {
                let _params = {
                    type: 'APP_ROLE',
                    visualId: sceneId.value,
                    visualType: sceneType.value
                }
                let _roleResult = await getRoleList(_params)
                if (_roleResult.code === 200) {
                    state.authList = _roleResult.data
                }
            } catch (err) {
                console.error(err)
            }
        }
        const onChangeAuthType = () => {
        }
        const goBack = () => {
            router.go(-1)
        }
        const closeCopyModal = () => {
            state.copyModalConfig.visible = false
        }
        return {
            sceneType,
            sceneId,
            state,
            adminRoleList,
            roleRoleList,
            pageRoleList,
            onChangeAuthType,
            goBack,
            closeCopyModal
        }
    },
})
</script>

<style lang='less' scoped>
@import '~@style/less/theme.less';

.portal-menu-authority {
    width: 100%;
    height: 100%;
}

.portal-menu-authority-wrapper {
    width: 100%;
    height: 100%;
    padding: 24px 48px 8px 48px;
    display: flex;
    flex-direction: column;
}

.portal-menu-authority-header {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: @font-color-1;
    .text-1();
}

.portal-menu-authority-return-router {
    margin-right: 8px;
    cursor: pointer;
}

.portal-menu-authority-header-content {
    margin-right: 4px;
}

.portal-menu-authority-appid {
    color: @primary-color;
    cursor: pointer;
}

.portal-menu-authority-content {
    flex: 1;
    height: 0;
}
</style>

<style>
.portal-menu-authority-header-right .ant-tabs-top>.ant-tabs-nav {
    margin: 0;
}
</style>